import { Container } from '@/components/Container'
import { FadeIn, FadeInStagger } from '@/components/FadeIn'
import ford from '../images/langenberg-dealership.jpg'
import canadabudgetcars from '../images/cbc-dealership.png'
import carmatcher from '../images/dealerships/carmatcher_open.png'
import carmatcherfall from '../images/carmatcherfall.png'
import warehouse from '../images/warehouse.webp'
import chevrolet from '../images/chevrolet.png'
import cds from '../images/cds.png'
import truck1 from '../images/truck1.png'
import truck2 from '../images/truck2.png'
import fiftyfirst from '../images/51st.jpeg'
import cargetgreen from '../images/cargetgreen.webp'
import budgettire from '../images/dealerships/budgettire.png'
import prairieeconomy from '../images/dealerships/prairieeconomy.png'
import chevsoon from '../images/dealerships/chevsoon.png'
import carmatchersaskatoon from '../images/dealerships/carmatcher_saskatoon.jpg'

const clients = [
  // top
  {
    text: 'Langenburg Motors',
    logo: ford,
    link: 'https://www.langenburgmotors.com/',
    alt: 'Langenburg Motors',
  },
  {
    text: "Langenberg GM (Chevrolet, Buick & GMC)",
    logo: chevsoon,
    link: '', // Replace with actual link
    alt: 'Chevrolet',
  },
  // 2nd
  {
    text: 'CanadaOne Dealer Services (Saskatoon)',
    logo: cds,
    link: 'https://www.canadaonedealerservices.ca/', // Replace with actual link
    alt: 'Carmatcher',
  },
  {
    text: 'Canada Carmatcher (Yorkton)',
    logo: carmatcher,
    link: 'https://carmatcher.ca', // Replace with actual link
    alt: 'Carmatcher',
  },
  // 3rd
  // {
  //   text: 'Canada Budget Cars (Saskatoon)',
  //   logo: canadabudgetcars,
  //   link: 'https://www.canadabudgetcars.ca/',
  //   alt: 'Canada Budget Cars',
  // },

  // {
  //   text: "Canada Carmatcher (Saskatoon, Fall '24)",
  //   logo: carmatcherfall,
  //   link: 'https://carmatcher.ca', // Replace with actual link
  //   alt: 'Carmatcher',
  // },
  //4th
  // {
  //   text: 'Carget Automotive (Saskatoon)',
  //   logo: fiftyfirst,
  //   link: 'https://www.carget.ca/',
  //   alt: '51st Street Supercentre',
  // },
  // {
  //   text: 'Carget Supercentre (Saskatoon)',
  //   logo: cargetgreen,
  //   link: 'https://www.cargetsupercentre.ca/', // Replace with actual link
  //   alt: 'Super Centre',
  // },
  {
    text: 'Canada CarMatcher (Saskatoon)',
    logo: carmatchersaskatoon,
    link: '', // Replace with actual link
    alt: 'Canada CarMatcher',
  },

  // 5th
  {
    text: "Budget Truck Center (Saskatoon, Coming Fall '24)",
    logo: truck1,
    link: 'https://www.canadabudgettrucks.ca/', // Replace with actual link
    alt: 'Carmatcher',
  },
  {
    text: "Prairie Truck Center (Yorkton, Winter '24)",
    logo: truck2,
    link: 'https://www.canadabudgettrucks.ca/', // Replace with actual link
    alt: 'Carmatcher',
  },
  {
    text: 'Canada Budget Tire',
    logo: budgettire,
    link: '', // Replace with actual link
    alt: 'Canada Budget Tire',
  },
  {
    text: 'Prairie EZ Financing, PrairieOne Leasing Co. & Prairie Economy Rentals',
    logo: prairieeconomy,
    link: '', // Replace with actual link
    alt: 'Canada Budget Tire',
  },

]

export function Dealerships() {
  return (
    <div className=" rounded-4xl py-6 ">
      <Container>
        <FadeIn className="flex items-center gap-x-8">
          <h2 className="text-center font-display text-sm font-semibold tracking-wider text-black sm:text-left">
            Our Dealerships
          </h2>
          <div className="h-px flex-auto bg-neutral-800" />
        </FadeIn>
        <FadeInStagger faster>
          <ul
            role="list"
            className="mt-10 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-2"
          >
            {clients.map(({ text, logo, link, alt }) => (
              <li key={text}>
                <FadeIn>
                  <div className="relative flex flex-col justify-center">
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      <div className="relative h-[200px] w-full overflow-hidden sm:h-[300px] sm:w-auto md:h-[400px]">
                        <img
                          src={logo}
                          alt={alt}
                          className="h-full w-full object-cover"
                        />
                        <div className="absolute bottom-0 w-full bg-black bg-opacity-50 p-2 text-center">
                          <div className="text-xl font-bold text-white">
                            {text}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </FadeIn>
              </li>
            ))}
          </ul>
        </FadeInStagger>
      </Container>
    </div>
  )
}
